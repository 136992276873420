import React, { Component } from 'react';
import Template from './views/Template';
import './App.css';

class App extends Component {
  render() {    
    return (
		<Template/>
    );
  }
}

export default App;
