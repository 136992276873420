import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';

import { Form, Navbar, Button, Container, Row, Col, Table } from 'react-bootstrap';
import axios from 'axios';
import dayjs from 'dayjs';

import SplineChart from "./line charts/Spline Chart";
import { isThisTypeNode } from 'typescript';

class Template extends Component {

  constructor(props) {
    super(props);
    this.state = {
      points: [],
      stripline: null,
      currentTime: dayjs().toDate(),
      currentPrice: 0,
      currnetHour: dayjs().startOf('hour').toDate(),
      orders: [],
    };
    this.hardReload = false;

    setInterval(() => {
      this.readData();
    }, 1000 * 60 * 5);

    this.readData();
  }


  readData() {
    const searchParams = new URLSearchParams();
    searchParams.append("start", dayjs(this.state.currentTime).subtract('4', 'hours').toISOString());
    searchParams.append("end", dayjs(this.state.currentTime).add('24', 'hours').toISOString());

    axios.get('https://dashboard.elering.ee/api/nps/price?' + searchParams.toString())
      .then(res => {

        const data = res.data;
        var currPrice = 0;
        this.setState({
          points: data.data.ee.map(function (val, index) {

            var date = dayjs(val.timestamp * 1000).startOf('hour').toDate();

            if (date.getTime() === dayjs().startOf('hour').toDate().getTime())
              currPrice = val.price;
            return {
              x: date,
              y: val.price
            };
          }),
          stripline:
          {
            startValue: dayjs().startOf('hour'),
            endValue: dayjs().endOf('hour'),
            color: "#d8d8d8",
            labelFontColor: "#a8a8a8",
            labe: "Current hour"
          },
          currentTime: dayjs().toDate(),
          currnetHour: dayjs().startOf('hour').toDate(),
          currentPrice: currPrice,
        });
      });

    axios.post('https://wf.belyaev.live/webhook/852f864f-1d28-4f4b-b2a0-777f9c10d868')
      .then(res => {
        console.log(res.data.parsed);
        this.setState({ orders: res.data.parsed });
      });
  }

  handleClick = () => {
    if (this.hardReload)
      window.location.reload(false);
    else {
      this.readData();
      this.hardReload = true;
      setTimeout(() => { this.hardReload = false }, 5000);

    }
  };


  render() {

    return (

      <div>
        <Container>
          <Row>
            <Col>
              <Navbar.Brand className='text-light'>
                Electricity price for {dayjs(this.state.currnetHour).format("HH:00")}: {this.state.currentPrice} euros
                <Form.Text id="udpated" muted  style={{fontSize:"10px"}}>
                  (updated at {dayjs(this.state.currentTime).format("HH:mm:ss DD MMMM")})
                </Form.Text>
              </Navbar.Brand>
            </Col>
            <Col xs lg="2">
              <Button variant="success"
                onClick={this.handleClick}>Refresh</Button>
            </Col>
          </Row>

        </Container>
        <SplineChart points={this.state.points} stripline={this.state.stripline} />

        <Container className='text-light' style={{marginTop: '20px'}}>
          <Row>
            <Col></Col>
            <Col>
              <Table responsive className="table text-light">
                <thead>
                  <tr>
                    <th>ORDER</th>
                    <th>DELIVERING DATE</th>
                    <th>STATE</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orders.map((_, index) => (
                    <tr key={index}>
                      <td>
                        {_.order}
                      </td>
                      <td>
                        {_.deliverDate ? dayjs(_.deliverDate).format('DD.MM.YYYY') : ""}
                      </td>
                      <td>
                        {_.status}
                      </td>
                    </tr>))}
                </tbody>
              </Table>

            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Template;