import React, { Component } from 'react';
import CanvasJSReact from '../../assets/canvasjs.react';
import { Container } from 'react-bootstrap';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class SplineChart extends Component {
	

	render() {
		const options = {
			animationEnabled: true,
			theme: "dark2",
			backgroundColor: "#212529",	
			toolTip:{   
				contentFormatter: function (e) {
					var content = " ";
					var date = e.entries[0].dataPoint.x;
					var value = e.entries[0].dataPoint.y;
					var color = value > 80 ? "text-danger" : "text-success";
					var dateLabel = date.getHours().toString()+":00";
						content += dateLabel + ": " + "<span class=\""+color+"\"><strong>" + value + "</strong></span>";
					//}
					return content;
				}      
			},
			axisX: {
				interval:1,
				lineThickness: 2,
				intervalType: "hour",        
				valueFormatString: "HH:00", 
				labelAngle: -90,
				stripLines: [
					this.props.stripline
				]
			},
			axisY: {
				title: "Price",
				prefix: "EUR ",
				includeZero: true
			},
			data: [{
				lineThickness: 3,
				yValueFormatString: "##.##",
				type: "stepLine",
				dataPoints: this.props.points,
			}]
		}

		return (
			<Container>
	
				<Container>
					<CanvasJSChart options={options}					
					/>					
				</Container>
			</Container>
		);
	}
}

export default SplineChart;                           